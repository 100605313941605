import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import {
  Seo,
  Layout,
  HeroWithImageBlock,
  PromoForm,
  PromoFormVN,
  TextBlocks,
} from "../../index.js"
import PopupBox from "../components/PopupBox/PopupBox.jsx"
const Pt = ({ data, pageContext }) => {
  let { heroTitle, heroSubTitle, heroImage } = data.pt.blocks[0]?.heroBlock
  let { title, image, content } = data.pt.blocks[1].firstBlock
  let ptImage = getImage(image?.localFile)
  let textBlockFirst = data.pt.blocks[2].textBlock
  let promoForm = data.pt.blocks[3].promotionFormBlock
  let textBlockSecond = data.pt.blocks[4].textBlock
  return (
    <Layout>
      <Seo
        title={data.pt.seo?.title}
        description={data.pt.seo?.metaDesc}
        featuredImage={
          data.pt.seo?.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />

      <HeroWithImageBlock
        heroImage={heroImage}
        heroTitle={{ normalTitle: heroTitle, strokeTitle: heroSubTitle }}
        isSlider={false}
        bgColor="#914cff"
        TextImage={{
          title: title,
          image: ptImage,
          className: "pt-custom-text-image-block",
          type: 2,
        }}
      >
        <ul>
          {content.map((item, key) => {
            return (
              <li key={key}>
                <span className="caret-right-fill-icon"></span>
                {item.contentHeader}
                <p>{item.contentText}</p>
              </li>
            )
          })}
        </ul>
      </HeroWithImageBlock>

      <TextBlocks
        TextBlock={3}
        textBlockTitle={textBlockFirst.textBlockTitle}
        textBlockSubTitle={textBlockFirst.textBlockSubTitle}
        textCard={textBlockFirst.textCard}
        className="text-block-type-3-custom pt_page_text_block"
        buttonText={textBlockFirst.buttonText}
        buttonLink={textBlockFirst.buttonLink.uri}
      />

      {pageContext.lang == "en" ? (
        <PromoForm
          customStyle="#000"
          title={promoForm.mainTitle}
          subtitle={promoForm.subTitle}
        />
      ) : (
        <PromoFormVN
          customStyle="#000"
          title={promoForm.mainTitle}
          subtitle={promoForm.subTitle}
        />
      )}
      <TextBlocks
        TextBlock={1}
        alignCenter={true}
        textBlockTitle={textBlockSecond.textBlockTitle}
        textBlockSubTitle={textBlockSecond.textBlockSubTitle}
        textCard={textBlockSecond.textCard}
        className="pt-textblock-type1-custome"
      />
    </Layout>
  )
}

export default Pt
export const query = graphql`
  query ($slug: String!, $lang: String!) {
    pt: wpPage(slug: { eq: $slug }, language: { slug: { eq: $lang } }) {
      slug
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfFirstBlock {
          firstBlock {
            title
            content {
              contentHeader
              contentText
            }
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on WpAcfTextBlock {
          textBlock {
            textBlockTitle
            textBlockSubTitle
            textBlockTitleStroke
            buttonText
            buttonLink {
              ... on WpPage {
                uri
              }
            }
            textCard {
              textCardContent
              textCardTitle
              textCardImage {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
        ... on WpAcfCtaBlock {
          ctaBlock {
            ctaTitle
            ctaStrokeTitle
            ctaButton {
              target
              title
              url
            }
          }
        }
        ... on WpAcfPromoFormBlock {
          promotionFormBlock {
            subTitle
            mainTitle
          }
        }
      }
    }
  }
`
